<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          small
          color="primary"
          class="elevation-0"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-card class="mx-auto" max-width="374">
        <v-img height="50" class="secondary"></v-img>
        <v-card-title>
          {{ currentUser ? currentUser.finess : 'Utilisateur inconnu' }}
        </v-card-title>
        <v-card-subtitle class="pb-2">
          {{ currentUser ? currentUser.email : 'Choisissez une option' }}
        </v-card-subtitle>

        <v-divider class="mx-4"></v-divider>

        <v-card-text class="pt-0">
          <v-list dense>
            <v-subheader>ACTIONS</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in actionItems"
                :key="i"
                :to="item.route"
              >
                <v-list-item-icon>
                  <v-icon v-text="item.icon"></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'UserMenu',

  data: () => ({}),

  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
    actionItems() {
      if (this.currentUser) {
        return [
          { text: 'Mon compte', icon: 'mdi-account', route: '/profile' },
          { text: 'Déconnexion', icon: 'mdi-logout', route: '/logout' }
        ]
      } else {
        return [
          { text: 'Connexion', icon: 'mdi-login', route: '/login' },
          { text: 'Inscription', icon: 'mdi-account-multiple-plus-outline', route: '/register' }
        ]
      }
    }
  }
}
</script>
